import React from "react"
import BlogPostPreviewLarge from "../../../blog/preview/large"
import BlogPostPreview from "../../../blog/preview"
import { graphql, useStaticQuery } from "gatsby"
import Button from "../../../utils/button"

const HomeBlogPreview = () => {
  const postsQuery = useStaticQuery(graphql`
    query MyQuery {
      allWpPost(sort: { fields: date, order: DESC }, limit: 5) {
        edges {
          node {
            title
            uri
            content
            date(formatString: "DD MMMM YYYY")
            categories {
              nodes {
                name
              }
            }
            
            extraPostDetails{
              author
              authorImage {
                mediaItemUrl
              }
            }
            
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(formats: WEBP, placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const allPosts = postsQuery.allWpPost.edges

  const largePost = allPosts[0].node

  const otherPosts = allPosts.filter((_, index) => index > 0)

  const showOtherPosts = otherPosts.map((post, index) => (
    <BlogPostPreview post={post.node} light key={index} />
  ))
  return (
    <>
      <div className="wrapper">
        <div className="md:w-10/12 mt-52 mx-auto">
          <BlogPostPreviewLarge post={largePost} light />
        </div>
      </div>
      <section className=" -mt-80 pt-80 pb-[85px] border-t border-white bg-black">
        <div className="wrapper mb-[142px]">
          <div className="md:w-10/12 mt-24 mx-auto ">
            <hr className="my-20" />
            <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-16 ">
              {showOtherPosts}
            </div>
          </div>
        </div>
        <Button className='mx-auto text-white justify-center' text='VISIT OUR BLOG' light url='/blog'/>
      </section>
    </>
  )
}

export default HomeBlogPreview
