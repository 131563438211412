import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { LinkedinIcon } from "../../../icons/social"

const stripParagraph = content => {
  //Strips the role off of the paragraph tag
  return content.replace(/<p>|<\/p>/g, "")
}
const Testimony = ({ testimonial }) => {
  const role = stripParagraph(testimonial.excerpt)
  const content = stripParagraph(testimonial.content)

  const title = testimonial.title

  const image = getImage(testimonial.featuredImage.node.localFile)

  return (
    <section className="text-left">
      <header className="flex items-center my-4">
        <GatsbyImage
          alt={title}
          image={image}
          className=" w-14 h-14 rounded-full"
        />

        <div className="pl-6">
          <h4 className="text-p14 text-dd-black-60">
            {title}{" "}
            <a
              href={testimonial.customFields.linkedinProfileUrl}
              rel="noreferrer"
              target="_blank"
              className="ml-2"
            >
              <LinkedinIcon />
            </a>{" "}
          </h4>
          <p
            className="text-[8px] tracking-widest uppercase text-dd-black-70 mt-0"
            dangerouslySetInnerHTML={{
              __html: role,
            }}
          ></p>
        </div>
      </header>
      <div
        className="text-dd-black-70"
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    </section>
  )
}

Testimony.propTypes = {}

export default Testimony
