import * as React from "react"

import Layout from "../components/layout"
import HomeBlogPreview from "../components/pages/home/blog"
import HomeIntro from "../components/pages/home/intro"
import HomeMentorship from "../components/pages/home/mentorship"

const IndexPage = () => (
  <Layout
    title="DearDesigner - For designers, by designers."
    ignoreSiteName={true}
    isHome
  >
    <HomeIntro />
    <HomeBlogPreview />
    <HomeMentorship />
    {/*<HomeResources />*/}
  </Layout>
)

export default IndexPage
