import ctl from "@netlify/classnames-template-literals"
import React from "react"
import JoinOurCommunity from "../../../utils/join-our-community"

const HomeIntro = () => {
  const homeintro_wrapper_style = ctl(`
  wrapper 
  sm:pt-20 
  pt-16 
  flex 
  items-center
  `)

  const homeintro_container_style = ctl(`
  md:w-7/12 
  w-full 
  md:min-w-[735px] 
  mx-auto 
  text-center`)

  const heading_style = ctl(`
  text-dd-black-70 
  sm:h1 h3`)

  const rider_text_style = ctl(`
  sm:w-10/12 text-dd-black-80 
  mx-auto
  `)

  const join_our_community_style = ctl(`
  max-w-[500px] 
  mx-auto 
  mt-16 
  md:px-0 
  px-8`)

  return (
    <section className={homeintro_wrapper_style}>
      <div className={homeintro_container_style}>
        <h1 className={heading_style}>
          For designers, <br/> by designers.
        </h1>
        <p className={`${rider_text_style} md-max:hidden`}>
          At DearDesigner, we are telling the stories of designers across Africa.
          <br/> <br/>
          With a well-curated content experience, hands-on mentorship, partnerships, educational programmes; we aim to give design the status it deserves in value, practice, and influence in everyday decisions—in Africa.
        </p>
        <p className={`${rider_text_style} md:hidden max-w-[301px]`}>
          At DearDesigner, we are telling the untold stories of designers across Africa. Through valuable content, tailored mentorship and innovative partnerships, we elevate the status of designers while helping design maintain its position as a skill of the future—in Africa.
        </p>
        <div className={join_our_community_style}>
          <JoinOurCommunity heading_center />
        </div>
      </div>
    </section>
  )
}

export default HomeIntro
