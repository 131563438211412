import React from "react"
import Tag from "../../../utils/tag"
import Button from "../../../utils/button"
import Testimony from "../../../mentorship/testimonials/testimony"
import { graphql, useStaticQuery } from "gatsby"

const HomeMentorship = () => {
  const testimonialsQuery = useStaticQuery(graphql`
    query {
      allWpTestimonial {
        edges {
          node {
            title
            content
            excerpt
            customFields {
              linkedinProfileUrl
            }

            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: PNG)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const testimonials = testimonialsQuery.allWpTestimonial.edges.map(
    (theTestimonial, index) => {
      const testimonial = theTestimonial.node

      return <Testimony testimonial={testimonial} key={index} />
    }
  )
  return (
    <section className="md:py-36 py-24 wrapper text-center">
      <Tag name="Mentorship" outline addon_style="md:!text-p16 !text-p12" />
      <h2 className="md:h2 h4 max-w-[742px] mx-auto text-dd-black-70 md:mt-[58px] mt-8 md-max:hidden">
        Bridging the experience gap in the African design industry.
      </h2>
      <h2 className="md:h2 h4 max-w-[280px] md:hidden mx-auto text-dd-black-70 md:mt-[58px] mt-8">
        Amplifying change, one mentorship at a time.
      </h2>
      <p className="max-w-[515px] p mx-auto text-dd-black-80 mb-10 md-max:hidden">
        We want to connect budding talents with more experienced hands,
        while creating a safe space for young designers to express their fears and learnings as they navigate the industry.
      </p>
      <p className="max-w-[301px] p mx-auto text-dd-black-80 mb-10 md:hidden">
        We are bridging the knowlege and experience gap in the design industy, while creating a safe space for young designers to express their fears and learnings as they navigate the industry.
      </p>
      <Button text="Read More" url="/mentorship" />
      <div className="grid lg:grid-cols-3 gap-16 md:w-10/12 mx-auto mt-[150px] md:mb-16">
        {testimonials}
      </div>
    </section>
  )
}

HomeMentorship.propTypes = {}

export default HomeMentorship
